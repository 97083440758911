import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import './index.css'
import useAuthenticateUser from './authenticateUser'

const Login: React.FC = () => {
	const { loginWithRedirect } = useAuth0()
	const navigate = useNavigate()
	const userAuthenticated = useAuthenticateUser()

	useEffect(() => {
		if (userAuthenticated === null) return

		if (userAuthenticated) {
			navigate('/home')
		} else {
			loginWithRedirect()
		}
	}, [userAuthenticated])

	return <></>
}

export default Login

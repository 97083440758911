import { getAxiosInstance } from '../utils/axios'
import { WorkerChain } from '../pages/WorkerChainBuilder/types'
import { Edge, Node } from 'reactflow'

export const fetchTemplate = async () => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.get('worker-chain/template')
}

export const fetchWorkerChains = async () => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.get('worker-chain')
}

export const saveWorkerChain = async (workerChainId: string | undefined, workerChain: WorkerChain) => {
	const axiosInstance = await getAxiosInstance()
	if (workerChainId) {
		return await axiosInstance.put(`worker-chain/${workerChainId}`, workerChain)
	} else {
		return await axiosInstance.post('worker-chain', workerChain)
	}
}

export const deleteWorkerChain = async (workerChainId: string) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.delete(`worker-chain/${workerChainId}`)
}

export const runWorkerChain = async (workerChain: WorkerChain, channelId: string, nodes: Node[], edges: Edge[]) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.post(`worker-chain/${workerChain.id}/run`, {
		input: {
			channel_id: channelId,
		},
		nodes: JSON.stringify(nodes),
		edges: JSON.stringify(edges),
	})
}

export const fetchWorkerChainRuns = async (workerChainId: string) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.get(`worker-chain/${workerChainId}/run`)
}

export const getWorkerChainRun = async (workerChainId: string, runId: string) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.get(`worker-chain/${workerChainId}/run/${runId}`)
}

export const getVersionsForWorkerChainRun = async (workerChainId: string, runId: string) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.get(`worker-chain/${workerChainId}/run/${runId}/version`)
}

export const getWorkerChainRunVersion = async (workerChainId: string, runId: string, versionId: string) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.get(`worker-chain/${workerChainId}/run/${runId}/version/${versionId}`)
}

export const sendFeedback = async (
	workerChainId: string,
	runId: string,
	versionId: string,
	workerId: string,
	workerRunId: string,
	workerRunVersionId: string,
	feedback: string
) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.post(`worker-chain/${workerChainId}/run/${runId}/version/${versionId}/feedback`, {
		worker_id: workerId,
		worker_run_id: workerRunId,
		worker_run_version_id: workerRunVersionId,
		feedback,
	})
}

export const resumeWorkerChainRun = async (workerChainId: string, runId: string, versionId: string) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.post(`worker-chain/${workerChainId}/run/${runId}/resume`, {
		version_id: versionId,
	})
}

export const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    const axiosInstance = await getAxiosInstance()
    return axiosInstance.post(`/worker-chain/upload/${encodeURI(file.name)}`,
        await file.arrayBuffer(),
        {
            headers: {
                'content-type': 'application/octet-stream',
            },
            transformRequest: [
                (data, headers) => {
                    // This will override any headers set by the axios instance
                    headers['Content-Type'] = 'application/octet-stream'
                    return data
                },
            ],
        });
}
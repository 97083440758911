import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { createWorker, updateWorker, fetchAspectRatioOptions } from '../../api/worker'
import { getUploadedDocuments } from '../../api/explorer'
import { toast } from 'react-toastify'

export enum WorkOutputType {
	TEXT = 'Text',
	IMAGE = 'Image',
	REACT_COMPONENT = 'React Component',
	VIDEO = 'Video',
}

export interface SecondBrain {
	file_id: string
	file_name: string
}

export interface Worker {
	id: string
	name: string
	prompt: string
	output_title: string
	output_type: string
	second_brains: string[]
	created_at: string
	metadata?: {
		aspect_ratio?: string
	}
}

export interface AspectRatioOption {
	ratio: string
	description: string
	size: string
	key: string
}

interface WorkerModalProps {
	isLoading?: boolean
	workerFormData?: Worker
	setWorkerFormData: (workerFormData: any) => void
	showModal: boolean
	setShowModal: (showModal: boolean) => void
	onSubmit?: () => Promise<void>
}  

export const WorkerModal = ({
	isLoading,
	workerFormData,
	setWorkerFormData,
	showModal,
	setShowModal,
	onSubmit,
}: WorkerModalProps) => {
	const [isUpdating, setIsUpdating] = useState(false)
	const [selectAll, setSelectAll] = useState(true)
	const [secondBrains, setSecondBrains] = useState<SecondBrain[]>([])
	const textareaRef = useRef<HTMLTextAreaElement>(null)
	const isEditing = workerFormData?.id != undefined
	const [aspectRatioOptions, setAspectRatioOptions] = useState<AspectRatioOption[]>([])

	const fetchSecondBrains = async () => {
		try {
			getUploadedDocuments().then((response) => {
				setSecondBrains(response.data.files)
			})
		} catch (error) {
			console.error('Error fetching second brains:', error)
		}
	}

	useEffect(() => {
		setWorkerFormData((prevState: Worker) => ({
			...prevState,
			second_brains: secondBrains.map((sb) => sb.file_id),
		}))
	}, [secondBrains])

	useEffect(() => {
		fetchSecondBrains()
	}, [])

	useEffect(() => {
		const fetchOptions = async () => {
			try {
				const response = await fetchAspectRatioOptions()
				setAspectRatioOptions(response.data.options)
				
				// Set default aspect ratio for IMAGE output type in edit mode
				if (isEditing && workerFormData?.output_type === 'IMAGE' && !workerFormData?.metadata?.aspect_ratio) {
					const defaultOption = response.data.options.find((option: AspectRatioOption) => option.ratio === '16:9') || response.data.options[0]
					setWorkerFormData((prevState: Worker) => ({
						...prevState,
						metadata: {
							...prevState.metadata,
							aspect_ratio: defaultOption?.key || ''
						}
					}))
				}
			} catch (error) {
				console.error('Failed to fetch aspect ratio options:', error)
				toast.error('Failed to load aspect ratio options')
			}
		}

		fetchOptions()
	}, [isEditing, workerFormData?.output_type])

	const handleInputChange = (e: any) => {
		const { name, value } = e.target
		if (name === 'second_brains') {
			const selectedOptions = Array.from(
				(e.target as HTMLSelectElement).selectedOptions,
				(option) => option.value
			)
			setWorkerFormData((prevState: Worker) => ({ ...prevState, [name]: selectedOptions }))
		} else if (name === 'output_type') {
			setWorkerFormData((prevState: Worker) => {
				if (value === 'IMAGE') {
					const defaultOption = aspectRatioOptions.find(option => option.ratio === '16:9') || aspectRatioOptions[0]
					return {
						...prevState,
						[name]: value,
						metadata: {
							...prevState.metadata,
							aspect_ratio: prevState.metadata?.aspect_ratio || defaultOption?.key || ''
						}
					}
				} else {
					const { metadata, ...restState } = prevState
					return {
						...restState,
						[name]: value,
						metadata: {}
					}
				}
			})
		} else if (name === 'aspect_ratio') {
			setWorkerFormData((prevState: Worker) => ({
				...prevState,
				metadata: {
					...prevState.metadata,
					aspect_ratio: value
				}
			}))
		} else {
			setWorkerFormData((prevState: Worker) => ({ ...prevState, [name]: value }))
		}
	}

	const handleSubmit = async (e: any) => {
		e.preventDefault()
		try {
			setIsUpdating(true)
			if (isEditing) {
				await updateWorker(workerFormData.id, workerFormData)
			} else {
				await createWorker(workerFormData)
			}
			onSubmit && (await onSubmit())
			setWorkerFormData(undefined)
			setShowModal(false)
		} catch (error) {
			console.error('Error saving worker:', error)
			toast.error('Error saving worker')
		} finally {
			setIsUpdating(false)
		}
	}

	const adjustHeight = () => {
		const textarea = textareaRef.current
		if (textarea) {
			textarea.style.height = 'auto'
			textarea.style.height = `${Math.min(textarea.scrollHeight, 300)}px`
		}
	}

	const handleSelectAll = () => {
		setWorkerFormData((prevState: Worker) => ({
			...prevState,
			second_brains: selectAll ? [] : secondBrains.map((sb) => sb.file_id),
		}))
		setSelectAll(!selectAll)
	}

	const handleSecondBrainChange = (fileId: string) => {
		setWorkerFormData((prevState: Worker) => {
			const currentSecondBrains = prevState?.second_brains || [];
			const updatedSecondBrains = currentSecondBrains.includes(fileId)
				? currentSecondBrains.filter((id) => id !== fileId)
				: [...currentSecondBrains, fileId];
			setSelectAll(updatedSecondBrains.length === secondBrains.length);
			return { ...prevState, second_brains: updatedSecondBrains };
		});
	};

	return (
		<Modal
			show={showModal}
			size='xl'
			onHide={() => {
				setShowModal(false)
				setWorkerFormData(undefined)
			}}>
			<Modal.Header closeButton>
				<Modal.Title>{isEditing ? 'Edit Worker' : 'Create New Worker'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{isLoading ? (
					<Spinner animation='border' role='status'>
						<span className='visually-hidden'>Loading...</span>
					</Spinner>
				) : (
					<Form onSubmit={handleSubmit}>
						<Form.Group className='mb-3'>
							<Form.Label>Worker Name</Form.Label>
							<Form.Control
								type='text'
								name='name'
								value={workerFormData?.name ?? ''}
								onChange={handleInputChange}
								required
								disabled={isUpdating}
							/>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Output Title</Form.Label>
							<Form.Control
								type='text'
								name='output_title'
								value={workerFormData?.output_title ?? ''}
								onChange={handleInputChange}
								required
								disabled={isUpdating}
							/>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Prompt</Form.Label>
							<Form.Control
								as='textarea'
								name='prompt'
								value={workerFormData?.prompt ?? ''}
								onChange={(e) => {
									handleInputChange(e)
									adjustHeight()
								}}
								ref={textareaRef}
								required
								style={{ minHeight: '100px', maxHeight: '300px', overflow: 'auto' }}
								disabled={isUpdating}
							/>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>
								Second Brains ({workerFormData?.second_brains?.length ?? 0} / {secondBrains.length}{' '}
								selected)
							</Form.Label>
							<div className='second-brain-checkbox-container'>
								<div className='select-all-container'>
									<Form.Check
										type='checkbox'
										label='Select All'
										checked={selectAll}
										onChange={handleSelectAll}
									/>
								</div>
								<div className='second-brain-checkbox-list'>
									{secondBrains.map(({ file_id, file_name }) => (
										<Form.Check
											key={file_id}
											type='checkbox'
											id={`second-brain-${file_id}`}
											label={file_name}
											checked={workerFormData?.second_brains?.includes(file_id)}
											onChange={() => handleSecondBrainChange(file_id)}
										/>
									))}
								</div>
							</div>
						</Form.Group>

						<Form.Group className='mb-3'>
							<Form.Label>Output Type</Form.Label>
							<Form.Select
								name='output_type'
								value={workerFormData?.output_type ?? 'TEXT'}
								onChange={handleInputChange}
								disabled={isEditing || isUpdating}
								required
							>
								{Object.entries(WorkOutputType).map(([value, name]) => (
									<option key={name} value={value}>
										{name}
									</option>
								))}
							</Form.Select>
						</Form.Group>

						{workerFormData?.output_type === 'IMAGE' && (
							<Form.Group className='mb-3'>
								<Form.Label>Aspect Ratio</Form.Label>
								<Form.Select
									name='aspect_ratio'
									value={workerFormData?.metadata?.aspect_ratio ?? ''}
									onChange={handleInputChange}
									disabled={isUpdating}
									required
								>
									{aspectRatioOptions.map((option) => (
										<option key={option.key} value={option.key}>
											{option.ratio} - {option.description} ({option.size})
										</option>
									))}
								</Form.Select>
							</Form.Group>
						)}

						<Button variant='primary' type='submit' disabled={isUpdating}>
							{isUpdating && (
								<Spinner
									as='span'
									animation='border'
									size='sm'
									role='status'
									aria-hidden='true'
									className='mx-2'
								/>
							)}
							{isEditing ? 'Update' : 'Create'}
						</Button>
					</Form>
				)}
			</Modal.Body>
		</Modal>
	)
}

import React from 'react'
import { Handle, NodeProps, Position } from 'reactflow'

const ChannelNode = ({ data }: NodeProps) => {
	return (
		<div
			style={{
				background: '#ffffff',
				border: `1px solid red`,
				borderRadius: '15px',
				padding: '20px',
				width: '200px',
				height: 'auto',
				boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
				fontFamily: 'Arial, sans-serif',
				textAlign: 'center',
			}}>
			{
				<Handle
					type='source'
					id='channel'
					position={Position.Bottom}
					style={{ background: 'red', width: 20, height: 20, bottom: -10 }}
				/>
			}
			<div
				style={{
					color: 'red',
					fontWeight: 'bold',
					fontSize: '18px',
				}}>
				{data.title}
			</div>
		</div>
	)
}

export default ChannelNode

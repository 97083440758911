import React, { useState, useEffect } from 'react'
import { Button, Col, Container, Dropdown, InputGroup, Row, Form, FormControl, Modal, SplitButton } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ChannelCard } from '../../components/Cards/ChannelCardHomePage'
import { createNewChannel, listAllChannels } from '../../api/channels'
import { ChannelInterface } from '../../components/Interfaces'
import { TrackResponse } from '../../api/posthogAPIMonitoring'
import Loader from '../../components/spinner'
import './index.css'

const ListProcessedVideos: React.FC = () => {
	const [channels, setChannels] = useState<ChannelInterface[] | null>(null)
	const [searchQuery, setSearchQuery] = useState<string>('')
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [showModal, setShowModal] = useState(false);
	const [newChannelName, setNewChannelName] = useState('');

	const navigate = useNavigate()

	const handleChannelSelect = (channelID: string) => {
		navigate(`/channel-details/${channelID}`)
	}

	// Fetch all channels
	const fetchData = async () => {
		try {
			setIsSubmitting(true)
			const response = await listAllChannels(true)

			TrackResponse(response, 'get/prompts', undefined)

			console.log(response)
			const data = response.data
			setChannels(data.channels)
		} catch (error: any) {
			console.error('Error fetching channels from API', error)
		} finally {
			setIsSubmitting(false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	const filteredChannels = channels
		?.filter(
			(channel) =>
				channel.handle.toLowerCase().includes(searchQuery.toLowerCase()) ||
				channel.displayName.toLowerCase().includes(searchQuery.toLowerCase())
		)
		.sort((a, b) => a.displayName.localeCompare(b.displayName))

	const handleAnalyzeChannel = () => {
		navigate('/process-channel')
	}

	const handleCreateChannel = async () => {
		try {
			await createNewChannel(newChannelName);
			setShowModal(false);
			setNewChannelName('');
			// Refresh the channel list
			fetchData();
		} catch (error) {
			console.error('Error creating new channel', error);
		}
	};

	return (
		<Container fluid className='d-flex flex-column outer-container'>
			<div className='btn-container'>
				<SplitButton
					variant='primary'
					title='Add Channel'
					onClick={() => handleAnalyzeChannel()}
					onSelect={(eventKey: string | null) => {
						if (eventKey === 'empty') {
							setShowModal(true);
						} else {
							handleAnalyzeChannel()
						}
					}}>
					<Dropdown.Item eventKey='non-empty'>Analyze Youtube Videos</Dropdown.Item>
					<Dropdown.Item eventKey='empty'>Create Empty Channel</Dropdown.Item>
				</SplitButton>
			</div>
			<h1 style={{ marginLeft: '20px' }}>Channels</h1>
			<Row className='align-items-center mb-3'>
				<div className='d-flex align-items-center' style={{ width: '98%' }}>
					<InputGroup>
						<FormControl
							type='text'
							placeholder='Search channels'
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							className='search-bar'
						/>
					</InputGroup>
				</div>
			</Row>
			<Row>
				<Col md={12} className='mb-3'>
					<div className='channel-list'>
						{filteredChannels?.map((channel, index) => (
							<ChannelCard
								key={index}
								channel={channel}
								handleChannelCardClick={() => handleChannelSelect(channel.id)}
							/>
						))}
					</div>
				</Col>
			</Row>
			<Modal show={showModal} onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Create New Channel</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label>Channel Name</Form.Label>
						<Form.Control
							type="text"
							value={newChannelName}
							onChange={(e) => setNewChannelName(e.target.value)}
							placeholder="Enter channel name"
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowModal(false)}>
						Cancel
					</Button>
					<Button variant="primary" onClick={handleCreateChannel}>
						Create Empty Channel
					</Button>
				</Modal.Footer>
			</Modal>

			{isSubmitting && <Loader />}
		</Container>
	)
}

export default ListProcessedVideos

import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import MarkdownPreview from '@uiw/react-markdown-preview'
import { ToolInteraction } from '../Interfaces';
import './index.css';

// AI tool interactions with their inputs and outputs
interface ChainOfThoughtToolsProps {
  toolInteractions: ToolInteraction[];
}

const ChainOfThoughtTools: React.FC<ChainOfThoughtToolsProps> = ({ toolInteractions }) => {
  if (!toolInteractions || toolInteractions.length === 0) {
    return null;
  }

  // Automatically detects and formats JSON strings/objects with proper markdown syntax
  const formatMessage = (message: any) => {
    if (typeof message === 'string') {
      // Parse potential JSON strings that were stringified
      try {
        const parsed = JSON.parse(message);
        return '```json\n' + JSON.stringify(parsed, null, 2) + '\n```';
      } catch {
        // If it's not JSON, return the original string
        return "> " + message;
      }
    }
    // If it's already an object, stringify it with markdown code block
    return '```json\n' + JSON.stringify(message, null, 2) + '\n```';
  };

  // Maps tool names to their corresponding FontAwesome icon classes
  const getToolIcon = (toolName: string) => {
    switch (toolName) {
      case 'query_metadata':
        return 'fa-database';
      case 'fetch_context':
        return 'fa-file-alt';
      case 'search_internet':
        return 'fa-search';
      case 'ingest_channel':
        return 'fa-download';
      default:
        return 'fa-tools';
    }
  };

  const MAX_HEIGHT = 400; // Height in pixels before showing the "Show More" button

  // Utility function to handle scroll position management
  const handleScrollPosition = (
    ref: React.RefObject<HTMLDivElement>,
    scrollPositionRef: React.MutableRefObject<number>,
    action: 'save' | 'restore'
  ) => {
    const modalContainer = ref.current?.closest('.modal-body') || null;
    
    if (action === 'save') {
      scrollPositionRef.current = modalContainer 
        ? modalContainer.scrollTop 
        : window.scrollY;
    } else { // restore
      if (modalContainer) {
        modalContainer.scrollTop = scrollPositionRef.current;
      } else {
        window.scrollTo({
          top: scrollPositionRef.current,
          behavior: 'instant'
        });
      }
    }
  };

  // Implements expandable code blocks with "Show More/Less" functionality
  // Uses ResizeObserver to dynamically detect when content exceeds max height
  const ExpandableCode: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [shouldShowButton, setShouldShowButton] = useState(false);
    const codeRef = React.useRef<HTMLDivElement>(null);
    // Preserves scroll position when expanding/collapsing to prevent page jumps
    const scrollPositionRef = React.useRef<number>(0);

    const handleShowMore = () => {
      handleScrollPosition(codeRef, scrollPositionRef, 'save');
      setIsExpanded(true);
    };

    const handleShowLess = () => {
      setIsExpanded(false);
      handleScrollPosition(codeRef, scrollPositionRef, 'restore');
    };

    React.useEffect(() => {
      if (!codeRef.current) return;

      const checkHeight = () => {
        if (codeRef.current && codeRef.current.scrollHeight > MAX_HEIGHT) {
          setShouldShowButton(true);
        } else {
          setShouldShowButton(false);
        }
      };

      // Create ResizeObserver
      const resizeObserver = new ResizeObserver(checkHeight);
      resizeObserver.observe(codeRef.current);

      // Initial check
      checkHeight();

      // Cleanup
      return () => resizeObserver.disconnect();
    }, []);

    return (
      <div className="worker-cot-tools__expandable">
        <div
          ref={codeRef}
          className="worker-cot-tools__content"
          style={{
            maxHeight: isExpanded ? 'none' : `${MAX_HEIGHT}px`,
          }}
        >
          {children}
        </div>
        {shouldShowButton && (
          <>
            {!isExpanded && (
              <div
                onClick={handleShowMore}
                className="worker-cot-tools__fade-overlay"
              >
                <span className="worker-cot-tools__show-more-text">
                  Show More
                </span>
              </div>
            )}
            {isExpanded && (
              <div className="worker-cot-tools__show-less">
                <span 
                  className="worker-cot-tools__show-more-text"
                  onClick={handleShowLess}
                >
                  Show Less
                </span>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  // Maintains scroll position when opening/closing the accordion
  // This prevents the page from jumping when the accordion state changes
  const headerRef = React.useRef<HTMLDivElement>(null);
  const scrollPositionRef = React.useRef<number>(0);

  const handleAccordionSelect = (eventKey: string | string[] | null | undefined) => {
    if (eventKey === '0') {
      handleScrollPosition(headerRef, scrollPositionRef, 'save');
    } else if (eventKey === null && headerRef.current) {
      handleScrollPosition(headerRef, scrollPositionRef, 'restore');
    }
  };

  return (
    <Accordion className="worker-cot-tools" onSelect={handleAccordionSelect}>
      <Accordion.Item eventKey="0">
        <Accordion.Header ref={headerRef} className="worker-cot-tools__header">
          <i className="fas fa-lightbulb text-warning me-2"></i>
          Chain of Thought
        </Accordion.Header>
        <Accordion.Body>
          {toolInteractions.map((interaction, index) => (
            <div key={index} className="worker-cot-tools__interaction">
              <div className="worker-cot-tools__tool-header">
                <strong>
                  <i className={`fas ${getToolIcon(interaction.content.name)} me-2`}></i>
                  {interaction.type === "tool_call" ? "Tool Call" : "Tool Response"}
                </strong> 
                {': '}{interaction.content.name}
              </div>
              <MarkdownPreview 
                source={formatMessage(interaction.content.message)}
                className="worker-cot-tools__markdown"
                wrapperElement={{
                  "data-color-mode": "light"
                }}
                components={{
                  code: ({ inline, children, ...props }: any) => {
                    if (inline) {
                      return <code {...props}>{children}</code>;
                    }
                    return (
                      <ExpandableCode>
                        <code style={{ whiteSpace: 'pre-wrap' }} {...props}>
                          {children}
                        </code>
                      </ExpandableCode>
                    );
                  },
                  h1: ({ children }) => <h1 style={{ fontSize: '1.1rem' }}>{children}</h1>,
                  h2: ({ children }) => <h2 style={{ fontSize: '1rem' }}>{children}</h2>,
                }}
              />
              {interaction.content.is_success !== undefined && (
                <p>
                  Status:{" "}
                  <span className={interaction.content.is_success ? "text-success" : "text-danger"}>
                    {interaction.content.is_success ? "Success" : "Failed"}
                  </span>
                </p>
              )}
            </div>
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ChainOfThoughtTools;
import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import './styles.css';

interface CopyToClipboardButtonProps {
  /** The content to copy to clipboard */
  content: string;
  /** Optional class name for additional styling */
  className?: string;
  /** Optional title for the button tooltip */
  title?: string;
  /** Optional callback after successful copy */
  onCopySuccess?: () => void;
  /** Optional callback after copy failure */
  onCopyError?: (error: unknown) => void;
  /** Optional variant for the button */
  variant?: string;
  /** Optional size for the button */
  size?: 'sm' | 'lg';
  /** Optional style for the button */
  style?: React.CSSProperties;
}

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  content,
  className = 'copy-to-clipboard-button',
  title = 'Copy as markdown',
  onCopySuccess,
  onCopyError,
  variant = 'outline-secondary',
  size = 'sm',
  style
}) => {
  const handleCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(content);
      toast.success('Copied to clipboard!');
      onCopySuccess?.();
    } catch (error) {
      toast.error('Failed to copy');
      onCopyError?.(error);
    }
  }, [content, onCopySuccess, onCopyError]);

  return (
    <Button
      variant={variant}
      size={size}
      onClick={handleCopy}
      className={className}
      title={title}
      style={style}
    >
      <i className="fas fa-copy" /> Copy as Markdown
    </Button>
  );
};

export default CopyToClipboardButton; 
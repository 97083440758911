import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Container, Table, Spinner } from 'react-bootstrap'
import PreviewComponent from '../../components/PreviewComponent'
import { getReport, getTestReport, listChannelReportsVersion } from '../../api/reports'
import { deleteTemplateVersion } from '../../api/templates'
import { ReportInterface } from '../../components/Interfaces'
import { toast } from 'react-toastify'
import moment from 'moment'

const ReportPreview: React.FC = () => {
	const { channelId, templateId, requestedAt } = useParams<{
		channelId: string
		templateId: string
		requestedAt?: string
	}>()
	const [sections, setSections] = useState<string[]>([])
	const [loading, setLoading] = useState(true)
	const [reportTitle, setReportTitle] = useState('')
	const [channelName, setChannelName] = useState('')
	const [time, setTime] = useState('')
	const [reportVersions, setReportVersions] = useState<ReportInterface[]>([])
	const [showTable, setShowTable] = useState<boolean>(false)
	const showVersionBar = channelId !== 'test'

	useEffect(() => {
		const fetchReport = async () => {
			try {
				if (channelId && templateId) {
					if (channelId == 'test') {
						getTestReport(templateId).then((response) => {
							setReportTitle(response.title)
							setChannelName(response.testedChannelName)
							setSections(response.testResultReportSections)
							setTime(response.testedAt)
						})
					} else {
						getReport(channelId, templateId, requestedAt).then((response) => {
							setReportTitle(response.title)
							setSections(response.sections)
							setTime(response.requestedAt)
						})
					}
				}
			} catch (error) {
				console.error('Error fetching report:', error)
			} finally {
				setLoading(false)
			}
		}

		fetchReport()
	}, [channelId, templateId, requestedAt])

	const extractTemplateVersion = async () => {
		try {
			const response = await listChannelReportsVersion(channelId, templateId)
			const data = response?.data
			setReportVersions(data.reports)
			setShowTable(!showTable)
		} catch (error: any) {
			console.error('Error fetching channel reports', error)
		}
	}

	const deleteVersion = async (requested_at: string) => {
		try {
			await deleteTemplateVersion(channelId, templateId, requested_at)
			toast.success('Version deleted successfully')
			extractTemplateVersion()
		} catch {
			toast.error('Error deleting the template version')
		}
	}

	const viewPreviousVersion = async (newRequestedAt: string) => {
		window.open(`/report/${channelId}/${templateId}/${newRequestedAt}`, '_blank')
	}

	const formatTime = (timeString: string) => {
		const date = new Date(timeString)
		const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric', year: 'numeric' }
		return `Last Updated on ${date.toLocaleDateString(undefined, options)}`
	}

	if (loading) {
		return (
			<Container fluid className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
				<Spinner animation='border' />
			</Container>
		)
	}

	return (
		<Container fluid>
			<h1>
				{channelName && `${channelName} - `}
				{reportTitle && `${reportTitle} - `}Report
			</h1>
			{showVersionBar && (
				<>
					<Button
						variant='primary'
						onClick={extractTemplateVersion}
						disabled={!sections?.length}
						style={{ marginRight: '10px' }}>
						{showTable ? 'Hide Table' : 'View All Versions'}
					</Button>
					{showTable ? (
						<Table striped bordered hover className='text-center justify-content-center align-items-center'>
							<thead>
								<tr>
									<th>Title</th>
									<th>Status</th>
									<th>Requested At</th>
									<th>View</th>
									<th>Delete</th>
								</tr>
							</thead>
							<tbody>
								{reportVersions.map((version, index) => (
									<tr key={index}>
										<td>{version.title}</td>
										<td>{version.status}</td>
										<td>{moment(version.requestedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
										<td>
											<Button
												variant='primary'
												onClick={() => viewPreviousVersion(version.requestedAt)}>
												View
											</Button>
										</td>
										<td>
											<Button variant='danger' onClick={() => deleteVersion(version.requestedAt)}>
												Delete
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					) : (
						<></>
					)}
				</>
			)}
			{sections?.map((section, index) => (
				<PreviewComponent key={index} code={section} />
			))}
			<div>
				<b>
					<i style={{ color: 'rgb(80, 95, 121)' }}>{time && formatTime(time)}</i>
				</b>
			</div>
		</Container>
	)
}

export default ReportPreview

import React, { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import useAuthenticateUser from './authenticateUser'
import { AUTH0_ROLE_NAMESPACE } from '../../constants'

const PrivateRoutes: React.FC<{ allowedRoles?: string[] }> = ({ allowedRoles = [] }) => {
	const userAuthenticated = useAuthenticateUser()

	const { user } = useAuth0()
	const roles: string[] = user?.[AUTH0_ROLE_NAMESPACE] || []
	const userAuthorized = allowedRoles.length === 0 || allowedRoles.some((role) => roles.includes(role))

	if (userAuthenticated === null || userAuthenticated === undefined) {
		return <div>Loading...</div>
	}

	if (!userAuthenticated) {
		return <Navigate to='/' />
	}

	if (!userAuthorized) {
		return <div>Access Denied</div>
	}

	return <Outlet />
}

export default PrivateRoutes

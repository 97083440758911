import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Col, Row, Button, Table, SplitButton, Dropdown, Modal, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Loader from '../../components/spinner'
import { FiRefreshCw } from 'react-icons/fi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEye } from '@fortawesome/free-solid-svg-icons'
import { FaArrowLeft } from 'react-icons/fa'
import { getChannelDetails } from '../../api/channels'
import { listChannelVideos } from '../../api/videos'
import { createVideoIdea } from '../../api/videoIdeas'
import { listTemplates, regenerateChannelReportForTemplate } from '../../api/templates'
import { TrackResponse } from '../../api/posthogAPIMonitoring'
import { listChannelReports } from '../../api/reports'
import { VideoCard } from '../../components/Cards/VideoCardThumbnail'
import { formatDuration, formatNumberWithCommas } from '../../utils/formatFunctions'
import { ChannelInterface, ReportInterface, Template, VideoInterface } from '../../components/Interfaces'
import { RenderViewReportButton } from './renderReportButton'
import '../../components/Cards/VideoCardThumbnails.css'
import { listContextDocuments, getContextDocument, createContextDocument, updateContextDocument, uploadFile, ContextDocument, ContextDocumentType, deleteContextDocument } from '../../api/contextDocuments'

function Channel() {
	const selectedChannel = useParams<{ channel_id: string }>().channel_id
	const [channelObject, setChannelObject] = useState<ChannelInterface | null>(null)
	const [channelVideos, setChannelVideos] = useState<VideoInterface[] | null>(null)
	const [channelReports, setChannelReports] = useState<ReportInterface[]>([])
	const [allReports, setAllReports] = useState<Template[]>([])
	const [showFullDescription, setShowFullDescription] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false) //for overall loader on screen
	const [isRefreshing, setIsRefreshing] = useState<boolean>(false) //for refresh on reports
	const [initialLoading, setInitialLoading] = useState(true) // for initial loading when page loads up
	const [contextDocuments, setContextDocuments] = useState<ContextDocument[]>()
	const [showModal, setShowModal] = useState(false)
	const [currentDocument, setCurrentDocument] = useState<ContextDocument>({
		name: '',
		type: ContextDocumentType.TEXT,
		content: '',
		data: {}
	})
	const [isSaving, setIsSaving] = useState(false);

	const navigate = useNavigate()

	const fetchAvailableReports = useCallback(async () => {
		if (!selectedChannel) {
			return
		}
		try {
			const response = await listChannelReports(selectedChannel)
			const data = response.data
			console.log('reports', data)
			setChannelReports(data.reports)
		} catch (error: any) {
			console.error('Error fetching channel reports', error)
			toast.error('Error fetching channel reports')
		}
	}, [selectedChannel])

	const fetchChannelDetails = useCallback(async () => {
		if (!selectedChannel) {
			return
		}
		try {
			const response = await getChannelDetails(selectedChannel)
			TrackResponse(response, 'get/prompts', undefined)
			const data = response.data
			setChannelObject(data)
		} catch (error: any) {
			console.error('Error fetching channel from API', error)
		}
	}, [selectedChannel])

	const fetchChannelVideos = useCallback(async () => {
		if (!selectedChannel) {
			return
		}
		try {
			const response = await listChannelVideos(selectedChannel)
			TrackResponse(response, `get/channels/videos/${selectedChannel}`, undefined)

			const data = response.data
			console.log('VIDEO : ', data)

			if (data.videos && Array.isArray(data.videos)) {
				const formattedVideos = data.videos.map((video: VideoInterface) => {
					const formattedDuration = formatDuration(video.duration)
					return {
						...video,
						formattedDuration,
					}
				})

				setChannelVideos(formattedVideos)
			} else {
				console.error('Invalid data format:', data)
			}
		} catch (error: any) {
			console.error('Error fetching channels from API', error)
		}
	}, [selectedChannel])

	const fetchTemplates = async () => {
		try {
			const response = await listTemplates()
			setAllReports(response.data.templates)
		} catch (error) {
			console.error('Error fetching templates', error)
		}
	}


	const fetchContextDocuments = useCallback(async () => {
		if (selectedChannel) {
			try {
				const response = await listContextDocuments(selectedChannel)
				setContextDocuments(response.data)
			} catch (error) {
				console.error('Error fetching context documents', error)
				toast.error('Error fetching context documents')
			}
		}
	}, [selectedChannel])

	useEffect(() => {
		if (!selectedChannel) {
			return
		}

		const fetchData = async () => {
			setInitialLoading(true)
			setIsSubmitting(true)

			await fetchChannelDetails()

			const reportsPromise = fetchAvailableReports()
			const templatesPromise = fetchTemplates()
			const videosPromise = fetchChannelVideos()
			const contextDocumentsPromise = fetchContextDocuments()

			await Promise.all([reportsPromise, templatesPromise, contextDocumentsPromise])
			setInitialLoading(false)

			await videosPromise
			setIsSubmitting(false)
		}

		fetchData()

		const interval = setInterval(() => {
			setIsRefreshing(true)
			fetchAvailableReports().finally(() => setIsRefreshing(false))
		}, 10000)

		return () => {
			clearInterval(interval)
		}
	}, [selectedChannel])

	const handleVideoClick = (videoId: string, outlierScore: number) => {
		navigate(`${videoId}`, { state: { outlierScore } })
	}

	const openReport = async (report: ReportInterface) => {
		if (!selectedChannel) {
			toast.error('Error')
			return
		}
		window.open(`/report/${selectedChannel}/${report.templateId}`, '_blank')
	}

	const regenerateReport = async (report_id: string) => {
		try {
			setIsSubmitting(true)
			const response = await regenerateChannelReportForTemplate(selectedChannel, report_id)
			const data = response.data
			console.log(data)
			fetchAvailableReports()
		} catch (error: any) {
			console.log(error)
		} finally {
			setIsSubmitting(false)
		}
	}

	const handleRefreshClick = async () => {
		setIsRefreshing(true)
		fetchAvailableReports().finally(() => setIsRefreshing(false))
	}

	const handleBrainstormNewIdea = useCallback(
		async (type = 'long') => {
			if (channelObject) {
				try {
					setIsSubmitting(true)
					const response = await createVideoIdea(channelObject.id, type)
					toast.success('New idea brainstormed successfully')
					navigate('/video-builder', {
						state: {
							filterChannelDisplayName: channelObject.displayName,
							newVideoIdeaId: response.data.id,
						},
					})
				} catch (error) {
					toast.error('Error brainstorming new idea')
				} finally {
					setIsSubmitting(false)
				}
			}
		},
		[channelObject]
	)


	const handleCreateContextDocument = () => {
		setCurrentDocument({
			name: '',
			type: ContextDocumentType.TEXT,
			content: '',
			data: {}
		})
		setShowModal(true)
	}

	const handleEditContextDocument = useCallback(async (id: string) => {
		if (selectedChannel) {
			try {
				const response = await getContextDocument(selectedChannel, id)
				setCurrentDocument({
					...response.data,
					type: ContextDocumentType.TEXT
				})
				setShowModal(true)
			} catch (error) {
				console.error('Error fetching context document', error)
				toast.error('Error fetching context document')
			}
		}
	}, [selectedChannel]);

	const handleDeleteContextDocument = useCallback(async (id: string) => {
		if (selectedChannel) {
			const isConfirmed = window.confirm('Are you sure you want to delete this context document?');
			if (isConfirmed) {
				try {
					await deleteContextDocument(selectedChannel, id)
					setShowModal(false)
					fetchContextDocuments()
					toast.success('Context document deleted successfully')
				} catch (error) {
					console.error('Error deleting context document', error)
					toast.error('Error deleting context document')
				}
			}
		}
	}, [selectedChannel]);

	const handleSaveContextDocument = useCallback(async () => {
		if (selectedChannel) {
			try {
				setIsSaving(true);
				if (currentDocument.id) {
					await updateContextDocument(selectedChannel, currentDocument.id, currentDocument);
				} else {
					await createContextDocument(selectedChannel, currentDocument);
				}
				setShowModal(false);
				fetchContextDocuments();
				toast.success('Context document saved successfully');
			} catch (error) {
				console.error('Error saving context document', error);
				toast.error('Error saving context document');
			} finally {
				setIsSaving(false);
			}
		}
	}, [selectedChannel, currentDocument]);

	const handleTypeChange = (type: ContextDocumentType) => {
		setCurrentDocument({ ...currentDocument, type, data: {} })
	}

	const handleContentChange = (content: string) => {
		setCurrentDocument({ ...currentDocument, content, data: { [currentDocument.type]: content } })
	}

	const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0]
		if (file && currentDocument) {
			try {
				const response = await uploadFile(file)
				setCurrentDocument({
					...currentDocument,
					data: { key: response.data }
				})
			} catch (error) {
				console.error('Error getting S3 upload key', error)
				toast.error('Error uploading file')
			}
		}
	}

	return (
		<Container>
			<FaArrowLeft className='me-2 back' onClick={() => navigate(`/home`)} style={{ cursor: 'pointer' }} />
			{initialLoading ? (
				<Loader />
			) : (
				<>
					<Row className='d-flex' style={{ marginTop: '1rem' }}>
						<p className='processed-video-heading'>Channel Stats</p>
						<Col md={5} sm={12} style={{ margin: '0 10px' }}>
							<Row className='channel-info'>
								<Col lg={3}>
									<img
										src={channelObject?.avatarUrl}
										alt={channelObject?.handle}
										className='channel-thumbnail'
									/>
								</Col>
								<Col lg={7}>
									<div className='channel-details-card'>
										<p className='channel-title'>{channelObject?.displayName}</p>
										<p className='channel-handle'>@{channelObject?.handle}</p>
										<p className='channel-videos'>{channelObject?.videoCount} videos</p>
										<p className='processed-videos'>
											Number of Analyzed Videos: {channelVideos?.length}
										</p>
									</div>
								</Col>
							</Row>
						</Col>
						<Col md={6} sm={12} style={{ marginLeft: '10px' }} className='justify-content-between'>
							<Row>
								<div className='subs-card'>
									<p className='card-title'>
										Subscribers <FontAwesomeIcon icon={faUser} className='arrow-icon' />
									</p>
									<p className='card-value'>
										{formatNumberWithCommas(channelObject?.subscriberCount)}
									</p>
								</div>
								<div className='subs-card'>
									<p className='card-title'>
										Total Views <FontAwesomeIcon icon={faEye} className='arrow-icon' />
									</p>
									<p className='card-value'>{formatNumberWithCommas(channelObject?.viewCount)}</p>
								</div>
							</Row>
							<Row>
								<p className='channel-description'>
									{channelObject?.description ?
										(showFullDescription
											? channelObject?.description
											: channelObject?.description?.slice(0, 150) +
											(channelObject?.description && channelObject.description.length > 50
												? '...'
												: '')
										) : ''}
									{channelObject?.description && channelObject.description.length > 50 && (
										<span
											className='see-more'
											onClick={() => setShowFullDescription(!showFullDescription)}
											style={{ color: 'blue', cursor: 'pointer' }}>
											{showFullDescription ? ' see less' : ' see more'}
										</span>
									)}
								</p>
							</Row>
						</Col>
					</Row>
					{channelObject && (
						<SplitButton
							key='new-idea'
							id='dropdown-new-idea'
							variant='primary'
							title='Brainstorm New Idea'
							onClick={() => handleBrainstormNewIdea()}
							onSelect={(eventKey: string | null) => {
								if (eventKey) {
									handleBrainstormNewIdea(eventKey)
								}
							}}>
							<Dropdown.Item eventKey='long' active>
								Long Form
							</Dropdown.Item>
							<Dropdown.Item eventKey='short'>Short Form</Dropdown.Item>
						</SplitButton>
					)}
					<Row className='channel-reports'>
						<p className='processed-video-heading'>
							SocialSurge Insights
							<Button variant='link' onClick={handleRefreshClick} style={{ marginLeft: '10px' }}>
								<FiRefreshCw size={24} className={isRefreshing ? 'rotate-animation' : ''} />
							</Button>
						</p>

						<div style={{ width: '90%' }}>
							<Table striped hover>
								<thead>
									<tr>
										<th style={{ width: '50%' }}>Title</th>
										<th
											style={{ width: '50%', textAlign: 'center' }}
											className='align-items-center'>
											View
										</th>
									</tr>
								</thead>
								<tbody className='reports-table'>
									{allReports
										.sort((a, b) => a.title.localeCompare(b.title))
										.map((report) => {
											const channelReport = channelReports.find(
												(rep) => rep.templateId === report.id
											)
											if (report.releaseStatus === 'released' && !channelReport) {
												return (
													<tr key={report.id}>
														<td style={{ width: '50%' }}>{report.title}</td>
														<td
															style={{ width: '50%' }}
															className='align-items-center text-center'>
															<Button
																onClick={() => regenerateReport(report.id)}
																className='report-btn'>
																Generate
															</Button>
														</td>
													</tr>
												)
											} else if (report.releaseStatus === 'released' && channelReport) {
												return (
													<tr key={channelReport.templateId}>
														<td style={{ width: '50%' }}>{report.title}</td>
														<td
															style={{ width: '50%' }}
															className='align-items-center text-center'>
															<RenderViewReportButton
																report_status={channelReport.status}
																template={channelReport}
																handleShowReport={openReport}
																regenerateReport={regenerateReport}
																refreshReports={handleRefreshClick}
															/>
														</td>
													</tr>
												)
											}
										})}
								</tbody>
							</Table>
						</div>
					</Row>
					<Row>
						<p className='processed-video-heading'>Analyzed Youtube Videos</p>
						{channelVideos?.map((video, index) => (
							<VideoCard
								key={index}
								video={video}
								isSelected={false}
								handleVideoCardClick={() => handleVideoClick(video.id, video.outlierScore)}
							/>
						))}
					</Row>
					{isSubmitting && <Loader />}
				</>
			)}
			{contextDocuments && <Row>
				<p className='processed-video-heading'>
					Context Packs
					<Button variant='primary' onClick={handleCreateContextDocument} style={{ marginLeft: '10px' }}>
						New
					</Button>
				</p>
				<div style={{ width: '90%' }}>
					<Table striped hover>
						<thead>
							<tr>
								<th>Name</th>
								<th>Edit</th>
								<th>Delete</th>
							</tr>
						</thead>
						<tbody>
							{contextDocuments.map((doc) => (
								<tr key={doc.id}>
									<td>{doc.name}</td>
									<td>
										<Button variant='info' onClick={() => {if (doc.id) handleEditContextDocument(doc.id)}}>
											Edit
										</Button>
									</td>
									<td>
										<Button variant='danger' onClick={() => {if (doc.id) handleDeleteContextDocument(doc.id)}}>
											Delete
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			</Row>}
			{isSubmitting && <Loader />}

			<Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>{currentDocument?.id ? 'Edit' : 'Create'} Context Document</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className='mb-3'>
							<Form.Label>Name</Form.Label>
							<Form.Control
								type='text'
								value={currentDocument?.name}
								onChange={(e) => setCurrentDocument({ ...currentDocument, name: e.target.value })}
							/>
						</Form.Group>
						{!currentDocument?.id && <Form.Group className='mb-3'>
							<Form.Label>Type</Form.Label>
							<div className='d-flex'>
								{Object.values(ContextDocumentType).map((type) => (
									<Button
										key={type}
										variant={currentDocument?.type === type ? 'primary' : 'outline-primary'}
										onClick={() => handleTypeChange(type)}
										className='flex-grow-1'
										style={{ marginRight: type !== ContextDocumentType.URL ? '0' : undefined }}
									>
										{type.charAt(0).toUpperCase() + type.slice(1)}
									</Button>
								))}
							</div>
						</Form.Group>}
						<Form.Group className='mb-3'>
							<Form.Label>Content</Form.Label>
							{currentDocument?.type === ContextDocumentType.TEXT && (
								<Form.Control
									as='textarea'
									rows={3}
									value={currentDocument?.content}
									onChange={(e) => handleContentChange(e.target.value)}
									style={{ minHeight: '200px' }}
								/>
							)}
							{currentDocument?.type === ContextDocumentType.URL && (
								<Form.Control
									type='url'
									value={currentDocument?.content}
									onChange={(e) => handleContentChange(e.target.value)}
									placeholder='Enter URL'
								/>
							)}
							{currentDocument.type === ContextDocumentType.FILE && (
								<Form.Control
									type='file'
									onChange={handleFileUpload}
								/>
							)}
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={() => setShowModal(false)}>
						Close
					</Button>
					<Button variant='primary' onClick={handleSaveContextDocument} disabled={isSaving}>
						{isSaving ? 'Saving...' : 'Save Changes'}
					</Button>
				</Modal.Footer>
			</Modal>
		</Container>
	)
}

export default Channel

import React, { useCallback, useState, useEffect, useRef, ChangeEvent, FormEvent, KeyboardEvent } from 'react'
import { useParams } from 'react-router-dom'
import useWebSocket from 'react-use-websocket'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faRobot, faUser, faDownload } from '@fortawesome/free-solid-svg-icons'
import MarkdownPreview from '@uiw/react-markdown-preview'
import ChannelDropdown from '../../components/Dropdown/channelDropdown'
import { WEBSOCKET_URL } from '../../constants'
import { toast } from 'react-toastify'
import './index.css'
import PreviewComponent from '../../components/PreviewComponent'
import { FaTimes } from 'react-icons/fa'
import { uploadFiles } from '../../api/templates'
import SecondBrainsDropdown from '../../components/Dropdown/secondBrainsDropdown'

interface Artifact {
	identifier: string
	title: string
	type: string
	content: string
}

interface Message {
	role: 'user' | 'assistant'
	content: string
	rawContent?: string
	objects: Artifact[]
	attachments?: string[]
}

export interface L2ExplorerProps {
	useContext?: boolean
}

function L2Explorer({ useContext = true }: L2ExplorerProps) {
	const { conversationId: conversationIdFromPath } = useParams<{ conversationId?: string }>()
	const [selectedChannel, setSelectedChannel] = useState<string>('')
	const [selectedChannelID, setSelectedChannelID] = useState<string>('')
	const [input, setInput] = useState<string>('')
	const [messages, setMessages] = useState<Message[]>([])
	const [currentMessage, setCurrentMessage] = useState<string>('')
	const [isAnalyzing, setIsAnalyzing] = useState<boolean>(false)
	const [receivingMessage, setReceivingMessage] = useState<boolean>(false)
	const [uploadingFiles, setUploadingFiles] = useState<boolean>(false)
	const [files, setFiles] = useState<{ [fileName: string]: string }>({})
	const [conversationId, setConversationId] = useState<string>()
	const [selectedSecondBrains, setSelectedSecondBrains] = useState<string[]>([])
	const messagesEndRef = useRef<HTMLDivElement>(null)
	// const scrollContainerRef = useRef<HTMLDivElement>(null);

	const { sendMessage, lastMessage } = useWebSocket(WEBSOCKET_URL, {
		onOpen: () => console.log('WebSocket connection established.'),
		onClose: () => console.log('WebSocket connection closed.'),
		onError: (error) => console.error('WebSocket error:', error),
		shouldReconnect: (closeEvent) => true,
	})

	const addObject = (text: string, type: string, message: Message) => {
		if (text.length > 0) {
			if (message.objects[message.objects.length - 1].type == type) {
				message.objects[message.objects.length - 1].content += text
			} else {
				message.objects.push({
					identifier: '',
					title: '',
					type: type,
					content: text,
				})
			}
		}
	}

	const processNewChunk = useCallback((prevMessages: Message[], chunk: string, source: string) => {
		const lastMsg = prevMessages[prevMessages.length - 1]
		// Note: strict mode calls this function twice from inside setMessages in dev mode.
		// Uncomment the following lines when running locally in dev mode.
		// if (lastMsg.content.endsWith(chunk)) {
		//     return prevMessages
		// }
		if (lastMsg.role === 'assistant') {
			lastMsg.content = lastMsg.content + chunk
			lastMsg.rawContent = (lastMsg.rawContent ?? '') + chunk
			if (
				'<antThinking'.startsWith(lastMsg.rawContent) ||
				lastMsg.rawContent.startsWith('<antThinking') ||
				lastMsg.rawContent.includes('<antThinking')
			) {
				// new object is artifact
				if (lastMsg.rawContent.includes('</antArtifact>')) {
					const match = lastMsg.rawContent.match(
						/(.*?)<antThinking>.*<\/antThinking>.*<antArtifact.*?identifier="(.*?)".*?type="(.*?)".*?title="(.*?)".*?>(.+?)<\/antArtifact>(.*)/s
					)
					if (match) {
						const [, before, identifier, type, title, content, after] = match
						addObject(before, source, lastMsg)
						lastMsg.objects.push({
							identifier,
							type,
							title,
							content,
						})
						addObject(after, source, lastMsg)
					}
					lastMsg.rawContent = ''
				}
			} else {
				// new object is string content
				addObject(chunk, source, lastMsg)
				lastMsg.rawContent = ''
			}
			return [...prevMessages]
		} else {
			return [
				...prevMessages,
				{
					role: 'assistant' as const,
					content: chunk,
					objects: [
						{
							identifier: '',
							type: source,
							title: '',
							content: chunk,
						},
					],
				},
			]
		}
	}, [])

	useEffect(() => {
		try {
			if (lastMessage !== null) {
				setReceivingMessage(true)
				const parsedMessage = JSON.parse(lastMessage.data)
				if (parsedMessage.type === 'L2Response' && parsedMessage.message) {
					setMessages((prevMessages) =>
						processNewChunk(prevMessages, parsedMessage.message, parsedMessage.source)
					)
				} else if (parsedMessage.message === null) {
					console.log('messages', messages)
					setReceivingMessage(false)
					setIsAnalyzing(false)
				}
			}
		} catch {
			console.log('Error')
		}
	}, [lastMessage])

	const handleSelect = (eventKey: string | null) => {
		if (eventKey) {
			const [channelId, channelName] = eventKey.split(':')
			setSelectedChannel(channelName)
			setSelectedChannelID(channelId)
			// Clear chat messages and current message
			setMessages([])
			setCurrentMessage('')
		}
	}

	const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setInput(event.target.value)
		event.target.style.height = 'auto'
		event.target.style.height = `${event.target.scrollHeight}px`
	}

	function makeiId(length: number) {
		let result = ''
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
		const charactersLength = characters.length
		let counter = 0
		while (counter < length) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength))
			counter += 1
		}
		return result
	}

	const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (!selectedChannel && useContext) {
			toast.error('Please select a channel.')
			return
		}
		if (input.trim() !== '') {
			let conversation_id = conversationId
			if (!conversation_id) {
				if (conversationIdFromPath) {
					conversation_id = conversationIdFromPath
				} else {
					conversation_id = makeiId(8)
				}
				setConversationId(conversation_id)
			}
			const userMessage = { role: 'user' as const, content: input, objects: [], attachments: Object.keys(files) }
			const payload = {
				explorer: 'L2',
				conversation_id,
				message: input,
				use_context: useContext,
				channel_id: selectedChannelID,
				attachments: Object.values(files),
				second_brains: selectedSecondBrains,
			}

			console.log('payload', payload)

			setTimeout(() => {
				sendMessage(JSON.stringify(payload))
				setMessages((prevMessages) => [...prevMessages, userMessage])
				setInput('')
				setFiles({})
				setIsAnalyzing(true)
			}, 100)
		}
	}

	const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault()
			handleFormSubmit(event as unknown as FormEvent<HTMLFormElement>)
		}
	}

	// const isAtBottom = () => {
	//     const container = scrollContainerRef.current;
	//     if (container) {
	//         return container.scrollHeight - container.scrollTop <= container.clientHeight + 1;
	//     }
	//     return true;
	// };

	const scrollToBottom = () => {
		if (messagesEndRef.current) {
			// && isAtBottom()) {
			messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	useEffect(() => {
		scrollToBottom()
	}, [messages, currentMessage])

	const downloadFile = (fileName: string, content: string) => {
		const element = document.createElement('a')
		const file = new Blob([content], { type: 'text/plain' })
		element.href = URL.createObjectURL(file)
		element.download = `${fileName}.tsx`
		document.body.appendChild(element)
		element.click()
	}

	const handleFilesUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const filesToUplaod = event.target.files
		if (filesToUplaod) {
			console.log('Uploading files', filesToUplaod)
			setUploadingFiles(true)
			// try {
			const keys = await uploadFiles(filesToUplaod)
			setFiles((oldFiles) => ({ ...oldFiles, ...keys }))
			// } catch (e) {
			// 	toast.error("Failed to upload file");
			// } finally {
			setUploadingFiles(false)
			// }
			event.target.value = ''
		}
	}

	const handleRemoveFile = (file: string) => {
		setFiles((oldFiles) => {
			delete oldFiles[file]
			return { ...oldFiles }
		})
	}

	const renderMessage = useCallback(
		(message: Message, index: number) => (
			<div key={index} className={`message ${message.role}`}>
				{message.role === 'assistant' && <FontAwesomeIcon icon={faRobot} className='icon' />}
				<div>
					{message.objects && message.objects.length ? (
						message.objects.map((messageObject, idx) => {
							const isReact = (obj: Artifact) =>
								obj.type == 'application/vnd.ant.react' || obj.type == 'text/html'
							const margin = idx > 0 && isReact(message.objects[idx - 1]) ? 20 : 0
							return typeof messageObject === 'string' ? (
								<MarkdownPreview
									className='message-content'
									source={messageObject}
									style={{ color: 'black', marginTop: margin }}
								/>
							) : isReact(messageObject) ? (
								<div className='d-flex align-items-center'>
									<PreviewComponent code={messageObject.content} />
									<FontAwesomeIcon
										icon={faDownload}
										onClick={() => downloadFile(messageObject.title, messageObject.content)}
										className='ml-2'
									/>
								</div>
							) : messageObject.type == 'image/jpeg' || messageObject.type == 'image/png' ? (
								<img src={messageObject.content} style={{ maxWidth: 1000 }} />
							) : (
								<MarkdownPreview
									className='message-content'
									source={messageObject.content}
									style={{ color: 'black', marginTop: margin }}
								/>
							)
						})
					) : (
						<MarkdownPreview
							className='message-content'
							source={message.content}
							style={{ color: 'black' }}
						/>
					)}
					{message.attachments && message.attachments.length > 0 && (
						<div style={{ marginTop: '10px' }}>
							Attachments
							{message.attachments.map((attachment, idx) => (
								<div key={idx} style={{ marginBottom: '5px' }}>
									{attachment}
								</div>
							))}
						</div>
					)}
				</div>
				{message.role === 'user' && <FontAwesomeIcon icon={faUser} className='icon' />}
			</div>
		),
		[]
	)

	return (
		<Container fluid className='d-flex flex-column outer-container'>
			<h1>{useContext ? 'L2 Explorer' : 'Claude Artifacts'}</h1>
			{useContext && (
				<Row className='align-items-center mb-3'>
					<Col className='d-flex justify-content-between'>
						<ChannelDropdown onSelect={handleSelect} selectedChannel={selectedChannel} />
						<SecondBrainsDropdown 
							onSelect={setSelectedSecondBrains} 
							selectedBrains={selectedSecondBrains}
						/>
					</Col>
				</Row>
			)}
			<div className='chat-container'>
				<div className='chat-messages' style={{ height: useContext ? '70vh' : '76vh' }}>
					{messages.map(renderMessage)}
					{isAnalyzing && (
						<div className='message assistant'>
							<FontAwesomeIcon icon={faSpinner} spin className='icon' />
							<p className='message-content'>Analyzing...</p>
						</div>
					)}
					<div ref={messagesEndRef} />
				</div>
				{Object.keys(files).length > 0 && (
					<>
						<Row className='mt-2'>
							<Form.Label>Selected Files</Form.Label>
							{Object.keys(files).map((file, index) => (
								<div key={index} className='d-flex align-items-center'>
									<span>{file}</span>
									<FaTimes
										className='ml-2 text-danger'
										style={{ cursor: 'pointer' }}
										onClick={() => handleRemoveFile(file)}
									/>
								</div>
							))}
						</Row>
					</>
				)}
				<form onSubmit={handleFormSubmit} className='chat-input'>
					<textarea
						className='message-input'
						value={input}
						onChange={handleInputChange}
						onKeyDown={handleKeyDown}
						placeholder='Enter query'
						disabled={isAnalyzing || receivingMessage}
					/>
					<input
						className='file-input'
						type='file'
						multiple
						onChange={handleFilesUpload}
						disabled={isAnalyzing || receivingMessage || uploadingFiles}
					/>
					<button
						type='submit'
						className='submit-btn'
						disabled={isAnalyzing || receivingMessage || uploadingFiles}>
						Send
					</button>
				</form>
			</div>
		</Container>
	)
}

export default L2Explorer

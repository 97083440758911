import React, { useCallback, useEffect, useState } from 'react'
import { Handle, Node, NodeProps, Position, useReactFlow } from 'reactflow'
import { FaPlus, FaGripVertical } from 'react-icons/fa'
import { Modal, Button, ListGroup } from 'react-bootstrap'

const OutputNode = ({ id, data }: NodeProps) => {
	const isViewMode = data.mode == 'view'
	const reactFlowInstance = useReactFlow()
	const [showModal, setShowModal] = useState(false)
	const [orderedWorkers, setOrderedWorkers] = useState<Node[]>()
	const [dragOverIndex, setDragOverIndex] = useState<number | null>(null)

	useEffect(() => {
		setOrderedWorkers(data.orderedWorkers);
	}, [data.orderedWorkers, setOrderedWorkers])

	const handleDoubleClick = useCallback(() => {
		if (isViewMode) {
			window.open(
				`/workflow/${data.worker_chain_id}/run/${data.worker_chain_run_id}/version/${data.worker_chain_run_version_id}`,
				'_blank'
			)
		} else {
			if (orderedWorkers) {
				setShowModal(true)
			}
		}
	}, [isViewMode, data, orderedWorkers])

	const handleClose = () => setShowModal(false)

	const handleSave = useCallback(() => {
		reactFlowInstance.setNodes(nodes => nodes.map(node => {
			if (node.id === id) {
				return {
					...node,
					data: {
						...node.data,
						orderedWorkers,
					}
				}
			}
			return node
		}))
		setShowModal(false)
	}, [reactFlowInstance, orderedWorkers, id]);

	const handleDragOver = (event: React.DragEvent<HTMLElement>, index: number) => {
		event.preventDefault();
		setDragOverIndex(index);
	};

	const handleDragEnd = useCallback((event: React.DragEvent<HTMLElement>, index: number) => {
		event.preventDefault();
		const newIndex = Number(event.dataTransfer.getData('index'));
		if (orderedWorkers) {
			const newOrder = [...orderedWorkers];
			const [removed] = newOrder.splice(newIndex, 1);
			newOrder.splice(index, 0, removed);
			setOrderedWorkers(newOrder);
		}
		setDragOverIndex(null);
	}, [orderedWorkers, setOrderedWorkers])

	return (
		<>
			<div
				onDoubleClick={handleDoubleClick}
				style={{
					background: '#ffffff',
					border: `1px solid black`,
					borderRadius: '15px',
					padding: '40px',
					width: '400px',
					height: 'auto',
					boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
					fontFamily: 'Arial, sans-serif',
					textAlign: 'center',
				}}>
				<Handle
					type='target'
					id='output'
					position={Position.Top}
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						background: 'transparent',
						border: 0,
						top: '-10px',
						left: '50%',
						width: 30,
						zIndex: 2,
						cursor: 'grab',
					}}>
					<div
						style={{ border: `2px solid black`, borderRadius: 5, width: '100%', backgroundColor: 'white' }}>
						<FaPlus style={{ color: 'black', fontSize: '20px' }} />
					</div>
					<span style={{ color: 'black' }}>Worker</span>
				</Handle>
				<div
					style={{
						color: 'black',
						fontWeight: 'bold',
						fontSize: '18px',
					}}>
					{data.title}
				</div>
			</div>

			<Modal show={showModal} onHide={handleClose} centered>
				<Modal.Header closeButton style={{ backgroundColor: '#f8f9fa', borderBottom: '1px solid #dee2e6' }}>
					<Modal.Title>Order Report Sections</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>
					{orderedWorkers && <ListGroup>
						{orderedWorkers.map((worker, index) => (
							<ListGroup.Item
								key={worker?.id}
								draggable
								onDragStart={(event) => {
									event.dataTransfer.setData('index', index.toString())
								}}
								onDragOver={(event) => handleDragOver(event, index)}
								onDrop={(event) => handleDragEnd(event, index)}
								data-index={index}
								style={{
									cursor: 'grab',
									backgroundColor: dragOverIndex === index ? '#e9ecef' : '#f8f9fa',
									border: '1px solid #dee2e6',
									marginBottom: '5px',
									display: 'flex',
									alignItems: 'center'
								}}>
								<FaGripVertical style={{ marginRight: '10px' }} />
								{worker?.data.output_title}
							</ListGroup.Item>
						))}
					</ListGroup>}
				</Modal.Body>
				<Modal.Footer style={{ backgroundColor: '#f8f9fa', borderTop: '1px solid #dee2e6' }}>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={handleSave}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default OutputNode

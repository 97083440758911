import { getAxiosInstance } from '../utils/axios'

export const listAllVideoIdeas = async () => {
	const axiosInstance = await getAxiosInstance()
	return axiosInstance.get(`channel/all/video_idea/`)
}

export const getVideoIdea = async (channelId: string, videoIdeaId: string) => {
	const axiosInstance = await getAxiosInstance()
	return axiosInstance.get(`channel/${channelId}/video_idea/${videoIdeaId}`)
}

export const updateVideoIdea = async (channelId: string, videoIdeaId: string, data: any) => {
	const axiosInstance = await getAxiosInstance()
	return axiosInstance.put(`channel/${channelId}/video_idea/${videoIdeaId}`, data)
}

export const submitFeedback = async (channelId: string, videoIdeaId: string, feedback: string) => {
	const axiosInstance = await getAxiosInstance()
	return axiosInstance.post(`channel/${channelId}/video_idea/${videoIdeaId}/feedback`, { feedback })
}

export const goToNextStage = async (channelId: string, videoIdeaId: string) => {
	const axiosInstance = await getAxiosInstance()
	return axiosInstance.post(`channel/${channelId}/video_idea/${videoIdeaId}/next`)
}

export const goToPrevStage = async (channelId: string, videoIdeaId: string) => {
	const axiosInstance = await getAxiosInstance()
	return axiosInstance.post(`channel/${channelId}/video_idea/${videoIdeaId}/prev`)
}

export const rewind = async (channelId: string, videoIdeaId: string) => {
	const axiosInstance = await getAxiosInstance()
	return axiosInstance.post(`channel/${channelId}/video_idea/${videoIdeaId}/rewind`)
}

export const deleteVideoIdea = async (channelId: string, videoIdeaId: string) => {
	const axiosInstance = await getAxiosInstance()
	return axiosInstance.delete(`channel/${channelId}/video_idea/${videoIdeaId}`)
}

export const createVideoIdea = async (channelId: string, type: string) => {
	try {
		const axiosInstance = await getAxiosInstance()
		const response = await axiosInstance.post(`/channel/${channelId}/video_idea?type=${type}`)
		return response
	} catch (error: any) {
		if (error.response && error.response.data && error.response.data.message) {
			throw new Error(error.response.data.message)
		} else {
			throw new Error('Unknown error')
		}
	}
}

import React, { useState, useEffect } from 'react'
import { Dropdown, FormControl } from 'react-bootstrap'
import { listAllChannels } from '../../api/channels'
import Loader from '../spinner'
import './index.css'
import { ChannelInterface } from '../Interfaces'

interface MarkdownDropdownProps {
	onSelect: (eventKey: string | null) => void
	selectedChannel: string
}

const ChannelDropdown: React.FC<MarkdownDropdownProps> = ({ onSelect, selectedChannel }) => {
	const [channels, setChannels] = useState<ChannelInterface[]>([])
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [searchTerm, setSearchTerm] = useState<string>('')

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsSubmitting(true)
				const response = await listAllChannels(false)
				let data = response.data.channels
				data = data.sort((c1: any, c2: any) => c1.displayName.localeCompare(c2.displayName))
				setChannels(data)
			} catch (error: any) {
				console.error('Error fetching channels from API', error)
			} finally {
				setIsSubmitting(false)
			}
		}
		fetchData()
	}, [])

	const filteredChannels = channels.filter((channel) =>
		channel.displayName.toLowerCase().includes(searchTerm.toLowerCase())
	)

	const CustomToggle = React.forwardRef<HTMLAnchorElement, any>(({ children, onClick }, ref) => (
		<a
			href=''
			ref={ref}
			onClick={(e) => {
				e.preventDefault()
				onClick(e)
			}}
			className='btn btn-primary dropdown-toggle'>
			{children}
		</a>
	))

	CustomToggle.displayName = 'CustomToggle'

	const CustomMenu = React.forwardRef<HTMLDivElement, any>(
		({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
			return (
				<div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
					<FormControl
						autoFocus
						className='mx-3 my-2 w-auto'
						placeholder='Type to filter...'
						onChange={(e) => setSearchTerm(e.target.value)}
						value={searchTerm}
					/>
					<ul className='list-unstyled mb-0'>
						{React.Children.toArray(children).filter(
							(child: any) =>
								!searchTerm || child.props.children.toLowerCase().includes(searchTerm.toLowerCase())
						)}
					</ul>
				</div>
			)
		}
	)

	const handleChannelSelect = (channel: string | null) => {
		setSearchTerm('')
		onSelect(channel)
	}

	CustomMenu.displayName = 'CustomMenu'

	return (
		<div>
			<Dropdown onSelect={handleChannelSelect}>
				<Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
					{selectedChannel || 'Select Channel'}
				</Dropdown.Toggle>

				<Dropdown.Menu as={CustomMenu} className='dropdown-list'>
					{filteredChannels.map((channel) => (
						<Dropdown.Item eventKey={`${channel.id}:${channel.displayName}`} key={channel.id}>
							{channel.displayName}
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>
			{isSubmitting && <Loader />}
		</div>
	)
}

export default ChannelDropdown

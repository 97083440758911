import { getAxiosInstance } from '../utils/axios'

export const fetchWorkers = async () => {
	try {
		const axiosInstance = await getAxiosInstance()
		return await axiosInstance.get('worker')
	} catch (error) {
		console.error('Error fetching workers:', error)
	}
}

export const getWorker = async (workerId: string) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.get(`worker/${workerId}`)
}

export const deleteWorker = async (workerId: string) => {
	const axiosInstance = await getAxiosInstance()
	return axiosInstance.delete(`worker/${workerId}`)
}

export const createWorker = async (formData: any) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.post('worker', formData)
}

export const updateWorker = async (workerId: string, formData: any) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.put(`worker/${workerId}`, formData)
}

export const fetchWorkerRuns = async (workerId: string) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.get(`worker/${workerId}/run`)
}

export const runWorker = async (workerId: string, channelId: string) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.post(`worker/${workerId}/run`, {
		channel_id: channelId,
	})
}

export const getWorkerRun = async (workerId: string, runId: string) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.get(`worker/${workerId}/run/${runId}`)
}

export const getWorkerRunVersions = async (workerId: string, runId: string) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.get(`worker/${workerId}/run/${runId}/version`)
}

export const getWorkerRunVersion = async (workerId: string, runId: string, versionId: string) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.get(`worker/${workerId}/run/${runId}/version/${versionId}`)
}

export const sendFeedback = async (workerId: string, runId: string, feedback: string, versionId?: string) => {
	const axiosInstance = await getAxiosInstance()
	let url = `worker/${workerId}/run/${runId}/feedback`
	if (versionId) {
		url = `worker/${workerId}/run/${runId}/version/${versionId}/feedback`
	}
	return await axiosInstance.post(url, { feedback })
}

export const fetchAspectRatioOptions = async () => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.get('/ideogram/aspect-ratio-options')
}

export const listAssociatedWorkflows = async (workerId: string) => {
	const axiosInstance = await getAxiosInstance()
	return await axiosInstance.get(`worker/${workerId}/associated-workflows`)
}
import { getAxiosInstance } from '../utils/axios';

export enum ContextDocumentType {
    TEXT = 'text',
    FILE = 'file',
    URL = 'url'
}

export interface ContextDocument {
    id?: string;
    channel_id?: string;
    name: string;
    type: ContextDocumentType;
    content: string;
    created_at?: string;
    data: {
        text?: string;
        key?: string;
        url?: string;
    };
}

export const listContextDocuments = async (channelId: string) => {
    const axiosInstance = await getAxiosInstance()
    return axiosInstance.get(`channel/${channelId}/context-document`);
};

export const getContextDocument = async (channelId: string, documentId: string) => {
    const axiosInstance = await getAxiosInstance()
    return axiosInstance.get(`channel/${channelId}/context-document/${documentId}`);
};

export const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    const axiosInstance = await getAxiosInstance()
    return axiosInstance.post(`/channel/upload/${encodeURI(file.name)}`,
        await file.arrayBuffer(),
        {
            headers: {
                'content-type': 'application/octet-stream',
            },
            transformRequest: [
                (data, headers) => {
                    // This will override any headers set by the axios instance
                    headers['Content-Type'] = 'application/octet-stream'
                    return data
                },
            ],
        });
}

export const createContextDocument = async (channelId: string, document: ContextDocument) => {
    const axiosInstance = await getAxiosInstance()
    return axiosInstance.post(`channel/${channelId}/context-document`, document);
};

export const updateContextDocument = async (channelId: string, documentId: string, document: ContextDocument) => {
    const axiosInstance = await getAxiosInstance()
    // only update name and content
    return axiosInstance.put(`channel/${channelId}/context-document/${documentId}`, {
        name: document.name,
        content: document.content
    });
};

export const deleteContextDocument = async (channelId: string, documentId: string) => {
    const axiosInstance = await getAxiosInstance()
    return axiosInstance.delete(`channel/${channelId}/context-document/${documentId}`);
}
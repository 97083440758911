import { getAxiosInstance } from '../utils/axios'

export const getChannelDetails = async (channelId: string) => {
	try {
		const axiosInstance = await getAxiosInstance()
		const response = await axiosInstance.get(`/channel/${channelId}`)
		return response
	} catch (error: any) {
		if (error.response && error.response.data && error.response.data.message) {
			throw new Error(error.response.data.message)
		} else {
			throw new Error('Unknown error')
		}
	}
}

export const createNewChannel = async (channelName: string) => {
	try {
		const axiosInstance = await getAxiosInstance()
		return await axiosInstance.post(`/channel`, {
			name: channelName,
		})
	} catch (error: any) {
		if (error.response && error.response.data && error.response.data.message) {
			throw new Error(error.response.data.message)
		} else {
			throw new Error('Unknown error')
		}
	}
}

export const listAllChannels = async (details?: boolean) => {
	try {
		const axiosInstance = await getAxiosInstance()
		return await axiosInstance.get(`/channel?details=${details}`)
	} catch (error: any) {
		if (error.response && error.response.data && error.response.data.message) {
			throw new Error(error.response.data.message)
		} else {
			throw new Error('Unknown error')
		}
	}
}

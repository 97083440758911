import React, { useState, useEffect } from 'react'
import { Dropdown, Form, Spinner } from 'react-bootstrap'
import { getUploadedDocuments } from '../../api/explorer'
import './index.css'

export interface SecondBrain {
    file_id: string
    file_name: string
}

interface SecondBrainsDropdownProps {
    onSelect: (selectedBrains: string[]) => void
    selectedBrains: string[]
}

const SecondBrainsDropdown: React.FC<SecondBrainsDropdownProps> = ({ onSelect, selectedBrains }) => {
    const [secondBrains, setSecondBrains] = useState<SecondBrain[]>([])
    const [selectAll, setSelectAll] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchSecondBrains = async () => {
            try {
                setIsLoading(true)
                const response = await getUploadedDocuments()
                const brains = response.data.files
                setSecondBrains(brains)
                if (selectAll) {
                    onSelect(brains.map((brain: SecondBrain) => brain.file_id))
                }
            } catch (error) {
                console.error('Error fetching second brains:', error)
            } finally {
                setIsLoading(false)
            }
        }
        fetchSecondBrains()
    }, [])

    const handleSelectAll = () => {
        const newSelectAll = !selectAll
        setSelectAll(newSelectAll)
        onSelect(newSelectAll ? secondBrains.map(brain => brain.file_id) : [])
    }

    const handleBrainSelect = (fileId: string) => {
        const newSelectedBrains = selectedBrains.includes(fileId)
            ? selectedBrains.filter(id => id !== fileId)
            : [...selectedBrains, fileId]
        
        setSelectAll(newSelectedBrains.length === secondBrains.length)
        onSelect(newSelectedBrains)
    }

    return (
        <Dropdown className="second-brains-dropdown">
            <Dropdown.Toggle 
                variant="primary" 
                id="second-brains-dropdown"
                disabled={isLoading}
            >
                Second Brains {"  "}
                {isLoading ? (
                    <>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                        />
                    </>
                ) : (
                    `(${selectedBrains.length}/${secondBrains.length})`
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <div className="px-3 py-2 border-bottom sticky-top">
                    <Form.Check
                        type="checkbox"
                        label="Select All"
                        checked={selectAll}
                        onChange={handleSelectAll}
                    />
                </div>
                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {secondBrains.map((brain) => (
                        <div key={brain.file_id} className="px-3 py-2">
                            <Form.Check
                                type="checkbox"
                                label={brain.file_name}
                                checked={selectedBrains.includes(brain.file_id)}
                                onChange={() => handleBrainSelect(brain.file_id)}
                            />
                        </div>
                    ))}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default SecondBrainsDropdown
